import React from 'react'
import { createRoot } from 'react-dom/client'
import { App } from './source/App'
import * as router from 'lucid-router'
import routes from './source/routes'

router.addRoutes(routes)

const rootElement = document.getElementById('content')
if (!rootElement) {
  console.error('Root element not found')
} else {
  const root = createRoot(rootElement)

  router.register(location => render(location))

  render(router.getLocation())

  function render(location) {
    root.render(<App location={location} />)
  }
}
